<template>
  <DashboardTemplate>
    <div class="pd-x-5 pd-b-3">
      <el-row align="middle" type="flex">
        <el-col :span="17">
          <h2>Transaction Log</h2>
        </el-col>

        <el-col :span="7" class="text-right is-flex ai-center js-end">
          <el-switch
            v-model="dbdVersion"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="2"
            :inactive-value="1"
            class="mg-r-7"
            @change="changeVersion"
          >
          </el-switch>
          {{ dbdVersion == 1 ? "DBD v1" : "DBD v2" }}
          <el-button
            type="primary"
            @click="exportDataCSV()"
            plain
            class="mg-l-5"
            ><i class="el-icon-upload2"></i> ส่งออกข้อมูล</el-button
          >
        </el-col>
      </el-row>
      <div class="bordered rounded pd-2 pd-t-4 pd-b-5 mg-b-3">
        <el-form label-position="top" class="is-flex">
          <el-form-item label="ค้นหา" class="mg-r-5 padding-less">
            <el-input
              v-model="filter.text"
              @input="searchOfText"
              @clear="search"
              clearable
              placeholder="ชื่อ"
            />
          </el-form-item>
          <el-form-item label="Action Type" class="mg-r-5 padding-less">
            <el-select
              v-model="filter.action"
              clearable
              filterable
              placeholder="แสดงทั้งหมด"
              @change="search"
              @clear="search"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="สถานะ" class="mg-r-5 padding-less">
            <el-select
              v-model="filter.status"
              clearable
              placeholder="แสดงทั้งหมด"
              @change="search"
              @clear="search"
            >
              <el-option label="Success" :value="1"> </el-option>
              <el-option label="Failed" :value="0"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="ช่วงวันที่" class="mg-r-5 padding-less">
            <el-date-picker
              @change="search"
              @clear="search"
              v-model="filter.date"
              type="daterange"
              align="right"
              start-placeholder="เริ่มต้น"
              end-placeholder="สิ้นสุด"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div v-loading="loading">
        <el-table border :data="logsList" style="width: calc(100% - 5px)">
          <el-table-column
            prop="createdAt"
            label="วันที่"
            header-align="center"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ moment(scope.row.createdAt).format("lll") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="username"
            label="ชื่อผู้ใช้งาน"
            header-align="center"
            width="240"
          >
          </el-table-column>
          <el-table-column
            prop="terminal"
            label="IP Address"
            header-align="center"
            align="center"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="activity"
            label="ความเคลื่อนไหว"
            header-align="center"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.activity }}
                <a
                  v-if="scope.row.requestOneTrust > 0"
                  @click="reqestLog(scope.row.requestOneTrust)"
                  class="text-primary"
                  >ดูเพิ่มเติม</a
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="สถานะ"
            header-align="center"
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.status"
                :class="
                  scope.row.status == 'Success' ? 'text-success' : 'text-danger'
                "
                >{{ scope.row.status }}</span
              >
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mg-y-3">
        <div class="block text-center">
          <el-pagination
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page.sync="page"
            :page-sizes="[15, 30, 50, 100, 500]"
            :page-size="15"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        :visible.sync="dialogModal"
        width="70%"
        center
        :show-close="false"
        :custom-class="'hide-head'"
        v-if="dialogModal"
      >
        <div style="">
          <h4 class="mg-t-0">
            Request:
            <span class="font-20">{{ logData.method }} {{ logData.path }}</span>
          </h4>
          <pre class="ql-syntax">{{ logData.request ? JSON.parse(logData.request) : logData.request }}</pre>
          <h4>Response</h4>
          <pre class="ql-syntax">{{ logData.response ? JSON.parse(logData.response): logData.response }}</pre>
        </div>
      </el-dialog>
    </div>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardAdminTemplate";
import { HTTP } from "@/service/axios";
import { CancelToken } from "axios";

export default {
  components: {
    DashboardTemplate,
  },
  data() {
    return {
      options: [
        {
          id: 1,
          label: "เข้าสู่ระบบ",
          value: "เข้าสู่ระบบ",
        },
        {
          id: 2,
          label: "เปลี่ยนรหัสผ่าน",
          value: "เปลี่ยนรหัสผ่าน",
        },
        {
          id: 3,
          label: "ลืมรหัสผ่าน",
          value: "ลืมรหัสผ่าน",
        },
        // {
        //   id: 4,
        //   label: "รีเซ็ตรหัสผ่าน",
        // },
        {
          id: 19,
          label: "สมัครสมาชิก",
          value: "สมัครสมาชิก",
        },
        {
          id: 5,
          label: "เปลี่ยนแปลงข้อมูลโปรไฟล์",
          value: "เปลี่ยนแปลงข้อมูลโปรไฟล์",
        },
        {
          id: 6,
          label: "เปลี่ยนแปลงข้อมูลบริษัท",
          value: "เปลี่ยนแปลงข้อมูลบริษัท",
        },
        {
          id: 28,
          label: "เชื่อมต่อข้อมูลกับ DBD",
          value: "เชื่อมต่อข้อมูลกับ DBD",
        },
        {
          id: 7,
          label: "อัปเดตข้อมูลบริษัทจาก DBD",
          value: "อัปเดตข้อมูลบริษัทจาก DBD",
        },
        {
          id: 8,
          label: "กรอกแบบประเมิน",
          value: "กรอกแบบประเมิน",
        },
        {
          id: 9,
          label: "ส่งแบบประเมินเรียบร้อย",
          value: "ส่งแบบประเมินเรียบร้อย",
        },
        {
          id: 10,
          label: "ทำแบบประเมินใหม่",
          value: "ทำแบบประเมินใหม่",
        },
        {
          id: 11,
          label: "ดาวน์โหลดผลประเมิน",
          value: "ดาวน์โหลดผลประเมิน",
        },
        {
          id: 12,
          label: "สร้างห้องระบบโหวต",
          value: "สร้างห้องระบบโหวต",
        },
        {
          id: 13,
          label: "เข้าร่วมระบบโหวต",
          value: "เข้าร่วมระบบโหวต",
        },
        {
          id: 14,
          label: "เปลี่ยนแปลงสถานะข้อมูลเพื่อการวิเคราะห์",
          value: "เปลี่ยนแปลงสถานะข้อมูลเพื่อการวิเคราะห์",
        },
        {
          id: 15,
          label: "ลบข้อมูลเพื่อการวิเคราะห์",
          value: "ลบข้อมูลเพื่อการวิเคราะห์",
        },
        {
          id: 16,
          label: "ส่งออกข้อมูลเพื่อการวิเคราะห์",
          value: "ส่งออกข้อมูลเพื่อการวิเคราะห์",
        },
        {
          id: 17,
          label: "เปลี่ยนข้อมูลเกณฑ์การประเมิน",
          value: "เปลี่ยนข้อมูลเกณฑ์การประเมิน",
        },
        {
          id: 18,
          label: "เปลี่ยนแปลงข้อมูลคำแนะนำ",
          value: "เปลี่ยนแปลงข้อมูลคำแนะนำ",
        },
        {
          id: 20,
          label: "กรอกแบบประเมินความพึงพอใจ",
          value: "กรอกแบบประเมินความพึงพอใจ",
        },
        {
          id: 21,
          label: "ผู้ดูแลแก้ไขข้อมูลนิติบุคคล",
          value: "ผู้ดูแลแก้ไขข้อมูลนิติบุคคล",
        },
        {
          id: 25,
          label: "ผู้ใช้งานเปลี่ยนแปลงข้อมูลการยินยอมเป็น ACTIVE",
          value: "ผู้ใช้งานเปลี่ยนแปลงข้อมูลการยินยอมเป็น ACTIVE",
        },
        {
          id: 26,
          label: "ผู้ใช้งานเปลี่ยนแปลงข้อมูลการยินยอมเป็น WITHDRAWN",
          value: "ผู้ใช้งานเปลี่ยนแปลงข้อมูลการยินยอมเป็น WITHDRAWN",
        },
        {
          id: 27,
          label: "ผู้ใช้งานเปลี่ยนแปลงข้อมูลการยินยอมเป็น NOTGIVEN",
          value: "ผู้ใช้งานเปลี่ยนแปลงข้อมูลการยินยอมเป็น NOTGIVEN",
        },
        {
          id: 22,
          label: "OneTrust เปลี่ยนแปลงข้อมูลการยินยอมเป็น ACTIVE",
          value: "OneTrust เปลี่ยนแปลงข้อมูลการยินยอมเป็น ACTIVE",
        },
        {
          id: 23,
          label: "OneTrust เปลี่ยนแปลงข้อมูลการยินยอมเป็น WITHDRAWN",
          value: "OneTrust เปลี่ยนแปลงข้อมูลการยินยอมเป็น WITHDRAWN",
        },
        {
          id: 24,
          label: "OneTrust เปลี่ยนแปลงข้อมูลการยินยอมเป็น NOTGIVEN",
          value: "OneTrust เปลี่ยนแปลงข้อมูลการยินยอมเป็น NOTGIVEN",
        },
        {
          id: 29,
          label: "เปลี่ยนแปลง Version DBD",
          value: "เปลี่ยนแปลง Version DBD",
        },
        {
          id: 30,
          label: "Log สมัครสมาชิกดึงข้อมูล company DBD",
          value: "Log สมัครสมาชิกดึงข้อมูล company DBD",
        },
        {
          id: 31,
          label: "Log สมัครสมาชิกดึงข้อมูล Finance DBD",
          value: "Log สมัครสมาชิกดึงข้อมูล Finance DBD",
        },
        {
          id: 32,
          label: "สร้างกำหนดการ Sync DBD",
          value: "สร้างกำหนดการ Sync DBD",
        },
        {
          id: 33,
          label: "ดำเนินการ Sync DBD",
          value: "ดำเนินการ Sync DBD",
        },
        {
          id: 34,
          label: "Synced ข้อมูล DBD",
          value: "Synced ข้อมูล DBD",
        },
        {
          id: 35,
          label: "สมัครเข้าร่วมโครงการ",
          value: "สมัครเข้าร่วมโครงการ",
        },
        {
          id: 36,
          label: "System admin แก้ไขข้อมูลส่วนตัวผู้ใช้งาน",
          value: "ถูกแก้ไขข้อมูลส่วนตัวโดย",
        },
        {
          id: 37,
          label: "System admin แก้ไขข้อมูลบริษัทผู้ใช้งาน",
          value: "ถูกแก้ไขข้อมูลบริษัทโดย ",
        },
        {
          id: 38,
          label: "System admin แก้ไขรหัสผ่านผู้ใช้งาน",
          value: "ถูกแก้ไขรหัสผ่านโดย",
        },
        {
          id: 39,
          label: "System admin ลบข้อมูลผู้ใช้งาน",
          value: "ถูกลบข้อมูลผู้ใช้โดย",
        },
        {
          id: 40,
          label: "SSO Integration",
          value: "SSO Integration",
        },
        {
          id: 41,
          label: "SSO Register",
          value: "SSO Register",
        },
        {
          id: 42,
          label: "Thailand Pavilion Request Data",
          value: "Thailand Pavilion Request Data",
        },
        {
          id: 43,
          label: "Thailand Pavilion Refresh Token",
          value: "Thailand Pavilion Refresh Token",
        },
        {
          id: 44,
          label: "ส่งข้อมูล Thailand Pavilion",
          value: "ส่งข้อมูล Thailand Pavilion",
        }, {
          id: 45,
          label: "คลิกลิงก์หลักสูตร",
          value: "คลิกลิงก์หลักสูตร",
        },
        //ตอนนี้เลขมากสุด 45
      ],
      dbdVersion: false,
      loading: true,
      logsList: [],
      page: 1,
      pageSize: 15,
      total: 0,
      filter: {
        text: "",
        activity: "",
        date: null,
        status: null,
      },
      delaySearch: null,
      source: "",
      logData: {
        response: null,
        request: null,
        method: null,
        path: null,
      },
      dialogModal: false,
    };
  },
  mounted() {
    this.getVersion();
    this.fetchLogsList();
  },
  methods: {
    async reqestLog(id) {
      try
      {
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        let res = await HTTP.post(`/get/logs/oneTrust/${id}`).catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          }
          console.log("API /get/logs/oneTrust/ Catch", e);
        });
        if (res.data.success) {
          this.logData.method = res.data.obj.method;
          this.logData.path = res.data.obj.path;

          if ( res.data.obj.response )
          {
            this.logData.response = JSON.parse(
              JSON.stringify( res.data.obj.response )
            );
          } else
          {
            this.logData.response = res.data.obj.response;
          }
          if ( res.data.obj.request ){
            this.logData.request = JSON.parse(
              JSON.stringify( res.data.obj.request )
            );
          } else {
            this.logData.request = res.data.obj.request 
          }
          this.dialogModal = true;
        }
      } catch {
        console.log("Fuction reqestLog Catch");
      }
    },
    async changeVersion ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.post(`change/version`, {
        version: this.dbdVersion,
      }).catch((e) => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        }
        console.log("changeVersion dbd error", e);
      });
      if (res.data.success) {
        this.fetchLogsList();
        this.getVersion();
        this.$message({
          message: "บันทึกสำเร็จ",
          type: "success",
        });
      }
    },
    async getVersion ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get(`version/dbd`).catch((e) => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        }
        console.log("version dbd error", e);
      });
      // console.log(res);
      if (res.data.success) {
        this.dbdVersion = res.data.obj;
      }
    },
    async searchOfText() {
      (this.page = 1), (this.pageSize = 15);
      if (this.delaySearch == null) {
        this.delaySearch = setTimeout(() => {
          this.loading = true;
          this.fetchLogsList();
          this.delaySearch = null;
        }, 800);
      } else {
        if (this.delaySearch) {
          clearTimeout(this.delaySearch);
          this.delaySearch = setTimeout(() => {
            this.loading = true;
            this.fetchLogsList();
            this.delaySearch = null;
          }, 800);
        }
      }
    },
    search() {
      this.loading = true;
      (this.page = 1), (this.pageSize = 15);
      this.fetchLogsList();
    },
    handleSizeChange(size) {
      this.loading = true;
      this.pageSize = size;
      this.fetchLogsList();
    },
    handleCurrentChange(page) {
      this.loading = true;
      this.page = page;
      this.fetchLogsList();
    },
    fetchLogsList() {
      let obj = {
        skip: (this.page - 1) * this.pageSize,
        limit: this.pageSize,
        search: this.filter.text ? this.filter.text : "all",
        action: this.filter.action ? this.filter.action : "all",
        startDate: this.filter.date
          ? `${this.filter.date[0].getFullYear()}-${
              this.filter.date[0].getMonth() + 1
            }-${this.filter.date[0].getDate()} :00:00:00`
          : "all",
        endDate: this.filter.date
          ? `${this.filter.date[1].getFullYear()}-${
              this.filter.date[1].getMonth() + 1
            }-${this.filter.date[1].getDate()} :23:59:59`
          : "all",
        status:
          this.filter.status === 1
            ? "Success"
            : this.filter.status === 0
            ? "Failed"
            : "all",
      };

      if (this.source) {
        this.source.cancel();
      }

      this.source = CancelToken.source();
      const cancelToken = this.source.token;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`logs`, obj, { cancelToken })
        .then((res) => {
          if (res.data.success) {
            this.logsList = res.data.obj;
            this.total = res.data.logs;
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          }
          console.log("fetchUserList error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    exportDataCSV() {
      HTTP.get(`export/logs`)
        .then((res) => {
          // console.log(res);
          if (res.data.success) {
            var name = "_blank";
            var specs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];

            const url = process.env.VUE_APP_IMAGE_SYSTEM_URL + res.data.obj;
            //const win = window.open(url, specs);
            var createA = document.createElement("a");
            createA.setAttribute("href", url);
            createA.setAttribute("target", "_blank");
            createA.click();
            this.$message({
              type: "success",
              message: "ส่งออกข้อมูลสำเร็จ",
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
